import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useAppDispatch } from "store";
import moment from "moment";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ALL_CAMPAIGNS, GET_SENDS_BY_CAMPAING } from "api/queries";
import {
  removeCampaign, removeSend,
} from "features/campaing/campaingThunks";
import { useModal, CenteredModal } from "../../components";
import { IReponse, Sends } from "../../interfaces/definition";




const AllCampaign = () => {
  const dispactch = useAppDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(true)
  const [page, setPage] = useState(1);

  const notificationDismissRef = useRef<HTMLButtonElement[] | null>(null);
  const { openModal, toggle } = useModal();
  const { loading, data = [], error, refetch: updateData } = useQuery(GET_ALL_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    variables: {
      pagination: {
        page,
        limit: 20,
      },
    },
  })
  const [getSends, { refetch, data: listSends = [] }] = useLazyQuery(
    GET_SENDS_BY_CAMPAING,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    const dismissNotification = (e: Event) => {
      const target = e.target as HTMLElement;
      const closestNotification = target.closest('.notification');
      if (closestNotification) {
        closestNotification.classList.add('is-hidden');
      }
    };

    if (notificationDismissRef.current) {
      const elements = Array.from(notificationDismissRef.current);
      elements.forEach((el: HTMLElement) => {
        el.addEventListener('click', dismissNotification);
      });
    }

    return () => {
      if (notificationDismissRef.current) {
        const elements = Array.from(notificationDismissRef.current);
        elements.forEach((el: HTMLElement) => {
          el.removeEventListener('click', dismissNotification);
        });
      }
    };
  }, []);

  if (loading) {
    return <div>Cargando</div>;
  }

  if (error) {
    return <div>Something went wrong </div>;
  }

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const loadSends = (campaignID: string) => {
    getSends({
      variables: {
        filter: { campaign: campaignID },
      },
    });
    openModal("sample-modal");
  };

  const removeSendById = (sendId) => {
    confirmAlert({
      title: "Confirmación",
      message: "¿Esta seguro de eliminar",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            dispactch(removeSend(sendId))
              .then((result) => {
                const { message } = result as unknown as IReponse;
                refetch();
                toast.success(message);
                toggle();
              })
              .catch(() => {
                toast.warn("Un error ha ocurrido");
              });
          },
        },
        {
          label: "No",
          onClick: () => toast("No se realizaron cambios"),
        },
      ],
    });
  };

  const remove = (campaignId: string) => {
    confirmAlert({
      title: "Confirmación",
      message: "¿Esta seguro de eliminar",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            dispactch(removeCampaign(campaignId))
              .then((result) => {
                const { message } = result as unknown as IReponse;
                toast.success(message);
                refetch()
              })
              .catch(() => {
                toast.warn("Un error ha ocurrido");
              });
          },
        },
        {
          label: "No",
          onClick: () => toast("No se realizaron cambios"),
        },
      ],
    });
  };

  const update = (campaignId: string) => {
    navigate('/admin/campaign', { state: { campaignId } });
  }

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Targets Automatizados</li>
                <li>Campañas</li>
              </ul>
            </div>
          </div>
          <div className="level-right" style={{ "display": "none" }}>
            <div className="level-item" />
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item"><h1 className="title">
                Total de campañas
              </h1></div>
            </div>
            <div className="level-right" style={{ "display": "none" }}>
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        {displayInfo && (
          <div className="notification is-info">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div>
                  <span className="icon"><i className="fa-solid fa-circle-info" /></span>
                  <b>Aqui podra encontrar tanto campañas activas como las pasadas.</b>
                </div>
              </div>
            </div>
            <div className="level-right">
              <button type="button" onClick={()=>setDisplayInfo(false)} className="button is-small is-white jb-notification-dismiss">Cerrar</button>
            </div>
          </div>
        </div>
        )}
        
        <div className="card has-table">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="fa-solid fa-table" />
              </span>
              Campañas
            </p>
            <a href="!#" className="card-header-icon" aria-label="link">
              <span className="icon">
                <i className="mdi mdi-reload" />
              </span>
            </a>
          </header>
          <div className="card-content">
            <div className="b-table has-pagination">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th aria-label="campañas" />
                      <th scope="col">Envios</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Fecha Inicio</th>
                      <th scope="col">Fecha Fin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.Campaigns.map((campaign, index) => (
                      <tr key={campaign.identifier}>
                        <td>
                          <div
                            className="dropdown is-hoverable"
                            onClick={() => handleToggle()}>
                            <div className="dropdown-trigger">
                              <button
                              aria-label="button"
                                type="button"
                                className="button is-info is-inverted"
                                aria-haspopup="true"
                                aria-controls={`dropdown-menu${index}`}>
                                <span className="icon is-small">
                                  <i className="fa-solid fa-ellipsis-vertical" />
                                </span>
                              </button>
                            </div>
                            <div
                              className="dropdown-menu"
                              id={`dropdown-menu${index}`}
                              role="menu">
                              <div className="dropdown-content">
                                <button
                                  type="button"
                                  className="button is-inverted is-outlined dropdown-item"
                                  onClick={() => {
                                    const { identifier: campaingIdentifier } = campaign;
                                    remove(campaingIdentifier);
                                  }}>
                                  Eliminar
                                </button>
                                <button
                                  type="button"
                                  aria-label="button"
                                  className="button is-inverted is-outlined dropdown-item"
                                  onClick={() => {
                                    const { identifier: campaingIdentifier } = campaign;
                                    update(campaingIdentifier);
                                  }}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="button"
                            aria-label="button"
                            onClick={() => {
                              const { identifier: campaingIdentifier } = campaign;
                              loadSends(campaingIdentifier);
                            }}>
                            <i className="fa-solid fa-arrow-up-right-from-square" />
                          </button>
                        </td>
                        <td>{campaign.name}</td>
                        <td>{campaign.description}</td>
                        <td>{moment(campaign.date_start).format("LL")}</td>
                        <td>{moment(campaign.date_end).format("LL")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="notification">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <button
                          type="button"
                          aria-label="button"
                          disabled={page === 1}
                          onClick={() => setPage((prev) => prev - 1)}
                          className="button">
                          <i className="fa-solid fa-chevron-left" />
                        </button>
                        <button
                          type="button"
                          aria-label="button"
                          className="button"
                          disabled={data.Campaigns.length === 0}
                          onClick={() => setPage((prev) => prev + 1)}>
                          <i className="fa-solid fa-chevron-right" />
                        </button>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <small>
                          Pagina {page}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CenteredModal
          tittle="Envios"
          data={listSends!.Sends as Sends[]}
          remove={removeSendById}
        />
      </section>
    </>
  );
};

export default AllCampaign;
