import { useState, useRef } from "react";

const VideoPlayer = ({ fileUrl }) => {
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el mensaje de carga
  const videoRef = useRef(null); // Referencia al elemento de video

  // Manejadores de eventos
  const handleWaiting = () => {
    setIsLoading(true); // Mostrar mensaje de carga
  };

  const handleCanPlay = () => {
    setIsLoading(false); // Ocultar mensaje de carga
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Reproductor de video */}
      <video
        ref={videoRef}
        controls
        width="100%"
        onWaiting={handleWaiting}
        onCanPlay={handleCanPlay}
        onPlaying={handleCanPlay}
      >
        <source src={fileUrl} type="video/mp4" />
        <source src={fileUrl} type="video/quicktime" /> {/* Soporte para .mov */}
        <track
          kind="captions"
          src="/path/to/subtitles.vtt"
          srcLang="es"
          label="Español"
          default
        />
        Tu navegador no soporta la reproducción de videos.
      </video>

      {/* Mensaje de carga */}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          Cargando video...
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;