import { useState } from 'react';
import { createApiInstance } from 'api/httpCommon';

const FileUploader = ({  onFileUpload, fileType }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const apiV2 = createApiInstance('V2');
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(""); // Limpiar errores anteriores
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Por favor, seleccione un archivo.');
      return;
    }

    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiV2.post('/whatsapp/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Asegúrate de que el encabezado esté configurado
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total!
          );
          setProgress(percentCompleted);
        },
      });

      onFileUpload(response.data.fileUrl); // Pasar la URL de la imagen al componente padre
      setError("");
    } catch (error) {
      setError(`Error cargando el archivo: ${error}. Por favor, inténtelo de nuevo.`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='field is-grouped'>
        <div className="control is-expanded">
            <input className='input' type="file" onChange={handleFileChange} accept={fileType} />
        </div>
        <div className='control'>
        <button className='button is-info' onClick={handleUpload} disabled={uploading || !file} type='button'>
                {uploading ? 'Cargando...' : 'Cargar'}
            </button>
        </div>
      {uploading && (
        <div>
          <progress value={progress} max="100" />
          <span>{progress}%</span>
        </div>
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default FileUploader;