import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { AppsRegisterClient } from 'schemas/formSchema';
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker, { registerLocale }  from "react-datepicker";
import { es } from "date-fns/locale";
import { InferType } from 'yup';

interface Step2Props {
  formData: SendInput;
  setFormData: (data: any) => void;
  nextStep: () => void;
  prevStep: () => void;
}

type SendInput = InferType<typeof AppsRegisterClient>;


const Step2: React.FC<Step2Props> = ({ formData, setFormData, nextStep, prevStep }) => {
  const { register, handleSubmit, formState: { errors }, control } = useForm<SendInput>({
    defaultValues: formData,
    resolver: yupResolver(AppsRegisterClient),
  });

  const onSubmit: SubmitHandler<SendInput> = data => {
    setFormData({ ...formData, ...data });
    nextStep();
  };

  registerLocale('es',es);

  return (
    <div className="column right has-text-centered">
      <h1 className="title is-4">Informacion Basica</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <div className={`select is-fullwidth ${errors.document && "is-danger"}`}>
                  <select placeholder='Tipo de Documento' {...register("document")}>
                    <option value="">Selecionar</option>
                    <option>Cedula de ciudadania</option>
                    <option>Pasaporte</option>
                    <option>otros</option>
                  </select>
                </div>
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-id-card" />
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className={`input ${errors.name && "is-danger"}`} type="text" placeholder="Numero de documento" {...register("number")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-hashtag" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className={`input ${errors.name && "is-danger"}`} type="text" placeholder="Nombres" {...register("name")} />
                <span className="icon is-small is-left">
                <i className="fa-regular fa-user"/>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className={`input ${errors.lastName && "is-danger"}`} type="text" placeholder="Apellidos" {...register("lastName")} />
                <span className="icon is-small is-left">
                <i className="fa-regular fa-user"/>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <div className="select is-fullwidth">
                  <select placeholder='Sexo' {...register("gender")}>
                    <option value="">Selecionar</option>
                    <option>Masculino</option>
                    <option>Femenino</option>
                    <option>Prefiero no decirlo</option>
                  </select>
                </div>
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-venus-mars" />
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <Controller
                  name='birthday'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      locale="es"
                      maxDate={new Date()}
                      dateFormat="yyyy/MM/dd"
                      selected={field.value}
                      placeholder="Fecha de Nacimiento"
                      showMonthDropdown 
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(date) => field.onChange(date)}
                      className={`input ${errors.birthday && "is-danger"
                        }`}
                      autoComplete="off"
                      isClearable
                      withPortal
                    />
                  )}
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-calendar-days" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field has-addons">
            <p className="control">
              <span className="select">
                <select {...register("coutryCode")}>
                  <option value="+57">+57</option>
                </select>
              </span>
            </p>
              <p className="control is-expanded has-icons-left">
                <input className={`input ${errors.movil && "is-danger"}`} type="text" placeholder="Telefono Móvil" {...register("movil")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-mobile-screen-button" />
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className="input" type="text" placeholder="Telefono fijo"{...register("phoneNumber")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-phone" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className={`input ${errors.email && "is-danger"}`} type="email" placeholder="Email"{...register("email")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-envelope" />
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <div className="select is-fullwidth">
                  <select placeholder='Estado Civil' {...register("maritalStatus")}>
                    <option value="">Selecionar</option>
                    <option>Soltero</option>
                    <option>Casado</option>
                    <option>Prefiero no decirlo</option>
                  </select>
                </div>
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-house-chimney-user" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className="input" type="text" placeholder="Dirección" {...register("address")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-map-location-dot" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='box'>
          <h3 className='subtitle'>Datos Adicionales</h3>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">¿Tiene hijos menores de edad?</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <label className="radio">
                    <input type="radio" value="True" {...register("children")} />
                    Si
                  </label>
                  <label className="radio">
                    <input type="radio" value="False" {...register("children")} />
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">¿Tiene mascotas?</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <label className="radio">
                    <input type="radio" value="True" {...register("pets")} />
                    Si
                  </label>
                  <label className="radio">
                    <input type="radio" value="False" {...register("pets")} />
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className="input" type="text" placeholder="Observaciones" {...register("comments")} />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-comment-dots" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="buttons is-flex is-justify-content-space-between">
          <button type="button" className="button is-info is-light is-medium" onClick={prevStep}>
              <span className="icon is-medium">
                <i className="fa-solid fa-circle-arrow-left fa-1x" />
              </span>
              <span>Anterior</span>
          </button>
          <button type="submit" className="button is-info is-medium">
            <span className="icon is-medium">
              <i className="fa-solid fa-arrow-right fa-1x" />
            </span>
            <span>Confirmar</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step2;