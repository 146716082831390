import { GET_SOURCE_NUMBERS, GET_TEMPLATES, SEND_TEMPLATE_MESSAGE } from "features/sends/sendThunks";
import { useEffect, useState } from "react";
import { InferType } from "yup";
import { toast } from "react-toastify";
import { useFormContext } from "react-hook-form";
import { useAppDispatch } from "store";
import { MessageTemplate, ScheduledSchema } from "schemas/formSchema";
import { MediaRenderer, FileUploader } from "components/globals";
import tagInfo from "../../data/user_tag.json";


type ScheduledInput = InferType<typeof ScheduledSchema>;
type MessageTemplateInput = InferType<typeof MessageTemplate>;

type ContainerMeta = {
  data: string;
  buttons: { type: string; text: string }[];
  header: string;
  sampleText: string;
  sampleHeader: string;
  enableSample: boolean;
  editTemplate: boolean;
  allowTemplateCategoryChange: boolean;
  addSecurityRecommendation: boolean;
};


const initialValues: MessageTemplateInput = {
  source: "",
  template: "",
  parameters: [],
  destination: "",
  type_media: "",
  media_file_url: "",
}


export const WAform = () => {

  const { register, setValue, formState: { errors } } = useFormContext<ScheduledInput>();

  const dispatch = useAppDispatch();
  const [source_numbers, setSource] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [placeholders, setPlaceholders] = useState<string[]>([]); // Placeholders detectados
  const [inputValues, setInputValues] = useState<Record<string, string>>({}); // Valores de los inputs
  const [totalParamenters, setTotal] = useState<number>(0)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [testData, setTestData] = useState<MessageTemplateInput>(initialValues);
  const [fileUrl, setFileUrl] = useState('');
  const [typeTemplate, setTypeTemplate] = useState('None');
  const [fileAccepted, setFileAccepted] = useState<string>();

  useEffect(() => {
    const fetchSource = async () => {
      dispatch(GET_SOURCE_NUMBERS())
        .then(response => {
          setSource(response.payload);
        })
        .catch(err => {
          console.log(err)
        })
    }

    fetchSource()
  }, [])

  useEffect(() => {
    setValue("send_method.wa_parameters", selectedOptions.toString())
  }, [selectedOptions])

  const getTemplate = (event) => {
    const phone_profile = event.target.value;
    if (!phone_profile) {
      return
    }

    setTestData(prev => ({ ...prev, source: phone_profile }))
    dispatch(GET_TEMPLATES(phone_profile))
      .then(response => {
        setList(response.payload)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    setTestData(prev => ({ ...prev, media_file_url: fileUrl }))

    let custom: any = {};

    switch (typeTemplate.toLocaleLowerCase()) {
      case "image":
        custom = {
          type: typeTemplate.toLowerCase(),
          image: {
            link: fileUrl
          }
        }
        break;
      case "video":
        custom = {
          type: typeTemplate.toLocaleLowerCase(),
          video: {
            link: fileUrl
          }
        }
        break;
      default:
        custom = {}
    }

    setValue("send_method.wa_message", JSON.stringify(custom))
  }, [fileUrl])

  useEffect(() => {
    const fullText = message;
    const detectedPlaceholders = fullText.match(/{{\d+}}/g) || [];
    setPlaceholders(detectedPlaceholders);
    setInputValues(
      detectedPlaceholders.reduce((acc, key) => ({ ...acc, [key]: "" }), {})
    );
    setTestData(prev => ({ ...prev, parameters: Object.values(inputValues) }))
  }, [message])

  useEffect(() => {
    setTestData(prev => ({ ...prev, parameters: Object.values(inputValues) }))
  }, [inputValues])

  useEffect(() => {
    setTotal(placeholders.length);

  }, [placeholders])

  const handleInputChange = (key: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
  };

  const reenumeratePlaceholders = (text: string): string => {
    const placeholders = text.match(/{{\d+}}/g); // Encuentra todos los placeholders
    if (!placeholders) return text; // Si no hay placeholders, retorna el texto original

    const uniquePlaceholders: string[] = [];
    let counter = 1;

    return text.replace(/{{\d+}}/g, () => {
      const newPlaceholder = `{{${counter}}}`;
      counter += 1;
      uniquePlaceholders.push(newPlaceholder);
      return newPlaceholder;
    });
  };

  const getReplacedText = () => {
    let replacedText = message;
    Object.entries(inputValues).forEach(([key, value]) => {
      replacedText = replacedText.replaceAll(key, value || key);
    });
    return replacedText;
  };

  const setDataNewTemplateSelected = (template_id) => {
    setTestData(prev => ({ ...prev, template: template_id }))

    const selectedObject = list.find(item => item.id === template_id);
    const parsedMeta: ContainerMeta = JSON.parse(selectedObject.containerMeta);

    const fullText = `${parsedMeta.header ? parsedMeta.header : ""} \n ${parsedMeta.data}`;

    setMessage(reenumeratePlaceholders(fullText));
    setTypeTemplate(selectedObject.templateType);

    setTestData(prev => ({ ...prev, type_media: selectedObject.templateType }))

    switch (selectedObject.templateType) {
      case "VIDEO":
        setFileAccepted("video/*");
        break;
      default:
        setFileAccepted("image/*");
    }
  }

  const findTemplate = (event: any) => {
    const template_id = event.target.value;
    initialValues.source = testData.source;
    setTestData(initialValues);
    setDataNewTemplateSelected(template_id);
  }

  const handleAddOption = (event: any) => {
    const propertie = event.target.value;
    if (selectedOptions.length < totalParamenters && propertie && !selectedOptions.includes(propertie)) {
      setSelectedOptions([...selectedOptions, propertie]);
    }
  };

  const handleRemoveOption = (value: string) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };


  const submitTemplate = () => {
    MessageTemplate.validate(testData).then(() => {
      dispatch(SEND_TEMPLATE_MESSAGE(testData))
        .then((response) => {
          toast.success(response.payload); // Muestra un mensaje de éxito
        })
        .catch((err) => {
          // Maneja errores de la acción `SEND_TEMPLATE_MESSAGE`
          toast.warning(`Algo no salió bien: ${err.message || err}`); // Muestra un mensaje de error
        });
    }).catch((err) => {
      toast.warning("Se requieren todos los parametros.");
    });

  };

  return (
    <div className="container mt-6">
      <div className="columns">
        <div className="column is-6">
          <h4 className="title is-4">Selector de Plantillas</h4>
          <div className="field">
            <label className="label">Numero de Origen</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  className={`is-capitalized ${errors.send_method?.wa_phone_number && "is-danger"}`}
                  aria-label="source"
                  id="source"
                  {...register("send_method.wa_phone_number")}
                  onChange={(event) => getTemplate(event)}>
                  <option >Seleccione una opción</option>
                  {source_numbers.map((option, index) => (
                    <option
                      key={`campaign-${index + 1}`}
                      value={option?.phone_number}>
                      {`${option?.app} - ${option?.phone_number}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Plantilla</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  disabled={list.length === 0}
                  className={`is-capitalized ${errors.send_method?.wa_template_id && "is-danger"}`}
                  aria-label="template"
                  {...register("send_method.wa_template_id")}
                  onChange={(event) => findTemplate(event)}
                  id="template">
                  <option>Seleccione una opción</option>
                  {list.map((option, index) => (
                    <option
                      key={`template-${index + 1}`}
                      value={option?.id}>
                      {`${option?.elementName}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">{`Tipo de Plantilla : ${typeTemplate}`}</label>
          </div>
          {typeTemplate === "IMAGE" || typeTemplate === "VIDEO" ?
            <FileUploader onFileUpload={setFileUrl} fileType={fileAccepted} />
            : ""}
          <div className="field is-narrow">
            <label className="label">Variables de integración</label>
            <div className="control">
              <div className="select is-fullwidth is-multiple">
                <select
                  size={4}
                  multiple
                  className="form-select w-30 form-select-sm"
                  onChange={(e) => handleAddOption(e)}
                >
                  {tagInfo.map((option, index) => (
                    <option
                      key={`datatag-${index + 1}`}
                      value={option.name}>
                      {`${option.name}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p className="help is-info">Debe seleccionar {totalParamenters} propiedades para esta plantilla</p>
          </div>
          <div className="field">
            <ul>
              {selectedOptions.map((option, index) => (
                <li key={option}>
                  {option}
                  <button type="button" className="button is-small is-rounded"  onClick={() => handleRemoveOption(option)}>.
                    <span className="icon is-small">
                      <i className="fa-solid fa-trash" />
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>

        </div>
        <div className="column">
          <div className="phone-frame">
            <div className="phone-content">
              <div className="message-content">

                <div className="whatsapp-bubble">
                  <p>
                  <MediaRenderer fileUrl={fileUrl} />
                    {`${getReplacedText()}`}
                  </p>
                </div>
                <div className="sender-info">Envia: John Doe</div>

              </div>
            </div>
          </div>
        </div>
        <div className="column is-one-quarter">
          <div className="box">
            <h3>Probar Plantilla</h3>
            {placeholders.map((placeholder) => (
              <div className="field" key={placeholder}>
                <label className="label is-small">{`Valor de prueba ${placeholder}`}:{" "}</label>
                <div className="control">
                  <input className="input is-small" type="text" placeholder="Parametro"
                    value={inputValues[placeholder] || ""}
                    onChange={(e) =>
                      handleInputChange(placeholder, e.target.value)
                    } />
                </div>
              </div>
            ))}
            <div className="field">
              <label className="label is-small">Destino</label>
              <div className="control">
                <input className="input is-small" type="text" placeholder="57123456789"
                  onChange={(e) => setTestData(prev => ({ ...prev, destination: e.target.value }))} />
              </div>
            </div>
            <div className="field">
              <p className="buttons">
                <button type="button" className="button" onClick={submitTemplate}>
                  <span className="icon">
                    <i className="fa-regular fa-paper-plane" />
                  </span>
                  <span>Probar</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}