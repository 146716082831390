import { gql } from "@apollo/client";

export const GET_ALL_CAMPAIGNS = gql`
  query GetCampaignsAllowAddSend($pagination: Pagination) {
    Campaigns(pagination: $pagination){
      identifier
      name
      description
      date_start
      date_end
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaignsAllowAddSend {
    Campaigns(filter: { allowAddSend: true }) {
      identifier
      name
      description
      date_start
      date_end
    }
  }
`;

export const GET_NEWSLETTER_APROVE = gql`
  query GetAprovedNewsletter {
    Newsletter(filter: { approve: true }) {
      id
      name
      img
    }
  }
`;

export const GET_NEWSLETTER_ALL = gql`
  query GetAllNewsletter($pagination: Pagination) {
    Newsletter(pagination: $pagination) {
      uuid
      name
      img
      approve
    }
  }
`;

export const GET_SENDS_BY_CAMPAIGN_SIMPLE = gql`
  query GetSends($filter: SendFilter) {
    Sends(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_CAMPAIGN_ID = gql`
  query GetCampaign($filter: CampaignFilter) {
    Campaigns(filter: $filter) {
      identifier
    }
  }
`;

export const EDIT_CAMPAIGN_ID = gql`
query EditCampaign($filter: CampaignFilter){
  Campaigns(filter:$filter) { identifier, name, description,date_start, date_end }
},
`;

export const GET_SENDS_BY_CAMPAING = gql`
  query GetSends($filter: SendFilter) {
    Sends(filter: $filter) {
      id
      geo_hierarchy
      geo_options
      comm_hierarchy
      comm_options
      payment_method
      payment_method_exception
      condition_metric
      cmetric_sign
      cmetric_value
      type_discount
      type_discount_value
      product_discount
      type_reward
      type_reward_value
      product_reward
      type_customer
      type_customer_value
      objetive
      customer_focus
      customer_rank
      date_rank_start
      date_rank_end
      name
      next_campaing
      customer_category
      customer_file_options
      campaign_name
      previous_campaign_to
      previous_send
      age_category
      genre
      birthday_month
      is_file_upload
      filter_comm_hierarchy
      filter_payment_method
      filter_condition_metric
      filter_type_discount
      filter_type_reward
    }
  }
`;
export const GET_SENDS_HEADER_CAMPAING = gql`
  query GetSends($filter: SendFilter) {
    Sends(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_SEND_PROFILE = gql`
  query GetSendProfile($filter: CampaignFilter) {
    GeographicalRanking {
      name
    }
    BusinessRanking {
      name
    }
    PaymentMethods {
      name
    }
    ConditionsMetrics {
      name
    }
    DiscountsRates {
      name
    }
    RewardsType {
      name
    }
    CustomerType {
      name
    }
    TargetCampaigns {
      name
    }
    CustomerFocus {
      name
    }
    Campaigns(filter: $filter) {
      identifier
      name
    }
  }
`;

export const GET_CLIENT_LOCATIONS = gql`
  query GetClientLocations($query: String!) {
    ClientLocations(query: $query) {
      name
    }
  }
`;

export const GET_CLIENT_REFERENCE = gql`
  query GetClientReference($query: String!) {
    ClientProducts(query: $query) {
      name
    }
  }
`;

export const GET_PROGRAMMING_ALL=gql`
  query GetProgramming($pagination: Pagination){
    Programming(pagination: $pagination){
        identifier,
        name,
        date_start,
        repeat_sending,
        active,
        state,
        create_at
    }
  }
`

export const EDIT_PROGRAMMING= gql`
query EditProgramming($filter: ProgrammingFilter, $filterSendMethod:SendMethodFilter) {
  Programming(filter: $filter) {
    identifier,
    name,
    newsletter_id,
    date_start,
    date_end,
    sender,
    sender_email,
    respond_email,
    subject,
    preheader,
    profile,
    repeat_sending,
    sending_time,
    range,
    sending_just_x_days,
    repeat_each_month,
    day_the_month,
    ordinal,
    weekday,
    active
  },
  Send_method(filter:$filterSendMethod){
    id,
    scheduled_id,
    type,
    newsletter_id,
    sender,
    sender_email,
    respond_email,
    subject,
    preheader,
    sms_message
  },
}`
