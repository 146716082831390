import VideoPlayer from "./VideoPlayer";

const MediaRenderer = ({ fileUrl }) => {
  // Función para verificar si la URL es un video
  const isVideo = (url) => {
    const videoExtensions = [".mp4", ".mov", ".webm", ".avi"];
    return videoExtensions.some((ext) => url.includes(ext));
  };

  // Función para verificar si la URL es una imagen
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
    return imageExtensions.some((ext) => url.includes(ext));
  };

  if (!fileUrl) {
    return null; // No mostrar nada si no hay URL
  }

  if (isVideo(fileUrl)) {
    return <VideoPlayer fileUrl={fileUrl} />;
  }

  if (isImage(fileUrl)) {
    return <img src={fileUrl} alt="whatsapp_template" style={{ maxWidth: "100%" }} />;
  }

  // Si no es ni video ni imagen, mostrar un mensaje de error o un componente predeterminado
  return <p>Formato de archivo no soportado.</p>;
};

export default MediaRenderer;