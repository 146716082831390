import {
  array,
  bool,
  boolean,
  date,
  mixed,
  number,
  object,
  string,
  ref,
} from "yup";

export const MessageTemplate = object({
  source: string().required("Se requiere el telefono de origen"),
  template: string().required("Seleccione una plantilla"),
  parameters: array(string()).nullable().default([]),
  destination: string().required("Indique el numero de destino."),
  type_media: string().required("No se indico tipo de media."),
  media_file_url: string().when('type_media', {
    is: (type_media:string) => type_media.toLowerCase() === 'video' || type_media.toLowerCase()  === 'imagen', // Corrección aquí
    then: string().required('La URL del archivo multimedia es obligatoria'),
    otherwise: string().nullable(),
  }),
});

export const CampaignSchema = object({
  identifier: string().nullable(),
  numberSends: number()
    .required("Debe ingresar el numero de envios de la campaña")
    .positive()
    .integer(),
  name: string().required("Debe asignar un nombre a la campaña"),
  description: string().required("La descripción es requerida"),
  date_start: date().required("debe indicar una fecha de inicio."),
  date_end: date().required("debe indicar una fecha final"),
});

export const loginSchema = object({
  g_recaptcha_response: string(),
  username: string()
    .email("Debe ingresar un correo valido")
    .required("Ingrese el correo del usuario"),
  password: string().required("Requerido"),
});

export const AddSendSchema = object({
  campaingIdentifier: string().required("Se debe seleccionar una campaña"),
  numberSends: number()
    .required("Se requiere un valor para el numero de envios.")
    .positive("Numero no valido.")
    .integer("Debe ingresar un numero entero positivo."),
});

export const SendsSchema = object({
  name: string().required(
    "debe agregar un nombre para la identificación del envío."
  ),
  objetive: array().min(
    1,
    "Debe seleccionar almenos un objetivo para la campaña"
  ),
  customer_focus: array().min(
    1,
    "Debe seleccionar almenos un objetivo para la campaña"
  ),
  customer_category: array().min(1, "selecione una categoria de cliente."),
  age_category: array().nullable(),
  genre: array().nullable(),
  birthday_month: array().nullable(),
  customer_rank: string().nullable(),
  sale_date_start: date().when("customer_rank", {
    is: (customerRank) => !!customerRank, // Comprueba si customerRank existe y tiene un valor definido
    then: date().required("Se requiere una fecha inicial requerida"),
    otherwise: date().transform(() => ""),
  }),
  sale_date_end: date().when("customer_rank", {
    is: (customerRank) => !!customerRank, // Comprueba si customerRank existe y tiene un valor definido
    then: date().required("Se requiere una fecha final"),
    otherwise: date().transform(() => ""),
  }),
  jegeografico: string().nullable(),
  jegeooptions: array().when("jegeografico", {
    is: (value) =>
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value !== "nacional",
    then: array().min(
      1,
      "Debe selecionar uno o varios valores de la seleccion."
    ),
    otherwise: array().transform((value, originalValue) => {
      if (
        originalValue &&
        originalValue.length &&
        originalValue[0] &&
        originalValue !== "nacional"
      ) {
        return [];
      }
      return value;
    }),
  }),
  jecomercial: string().nullable(),
  jcoptions: array().when("jecomercial", {
    is: (value) =>
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value !== "todos",
    then: array().min(
      1,
      "Debe seleccionar uno varios valores de la seleccion."
    ),
    otherwise: array().transform((value, originalValue) => {
      if (
        originalValue &&
        originalValue.length &&
        originalValue[0] &&
        originalValue !== "todos"
      ) {
        return [];
      }
      return value;
    }),
  }),
  payment_method: string().nullable(),
  payment_list: array().when("payment_method", {
    is: (value) =>
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value === "todos excepto",
    then: array().min(1, "Seleccione los metodos de pago a descartar."),
    otherwise: array().transform((value, originalValue) => {
      if (
        originalValue &&
        originalValue.length &&
        originalValue[0] &&
        originalValue === "todos excepto"
      ) {
        return [];
      }
      return value;
    }),
  }),
  condition_metrica: string().nullable(),
  operator_metrica: string().when("condition_metrica", {
    is: (value) => value !== undefined && value !== null && value !== "",
    then: string().required("Selecione un operador"),
    otherwise: string().transform(() => ""),
  }),
  value_metrica: string().when("condition_metrica", {
    is: (value) => value !== undefined && value !== null && value !== "",
    then: string().required("Debe ingresar un valor"),
    otherwise: string().transform(() => ""),
  }),
  type_discount: string().nullable(),
  next_campaing: date().when("type_discount", {
    is: (value: string) => value === "BONORECOMPRA",
    then: date().required("Debe ingresar la fecha de la siguiente campaña"),
    otherwise: date().transform(() => ""),
  }),
  value_discont: string().when("type_discount", {
    is: (value) => value === "PORCENTAJEDEDESCUENTO",
    then: string().required("Debe ingresar el valor del descuento"),
    otherwise: string().transform(() => ""),
  }),
  product_discount: array().when("type_discount", {
    is: (value) => value === "C.C.ALACOMPRADEOTROSPRODUCTOS",
    then: array().min(1, "Debe seleccionar los productos"),
    otherwise: array().transform((value, originalValue) => {
      if (originalValue && originalValue.length && originalValue[0]) {
        return [];
      }
      return value;
    }),
  }),
  type_reward: string().nullable(),
  value_reward: string().when("type_reward", {
    is: (value) => value === "VALORENEFECTIVO",
    then: string().required("Debe ingresar un porcentaje de descuento"),
    otherwise: string().transform(() => ""),
  }),
  product_reward: array().when("type_reward", {
    is: (value) => value === "UNIDADDEPRODUCTO",
    then: array().min(1, "Debe seleccionar los productos"),
    otherwise: array().transform((value, originalValue) => {
      if (originalValue && originalValue.length && originalValue[0]) {
        return [];
      }
      return value;
    }),
  }),
  type_customers: string().nullable(),
  value_customer: array().when("type_customers", {
    is: (value) =>
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value === "todos excepto",
    then: array().min(1, "Debe seleccionar los tipos de clientes"),
    otherwise: array().transform(() => ""),
  }),
  customer_file: mixed()
    .nullable()
    .test("fileType", "El archivo debe ser de tipo CSV", (value) => {
      if (value && value.type) {
        return value.type === "text/csv";
      }
      return true;
    }),
  customer_file_options: string().when("customer_file", {
    is: (value) => value !== null,
    then: string().required("Indique si los clientes seran o no incluidos"),
    otherwise: string()
      .nullable()
      .default(() => "Mensaje personalizado si no se proporciona el archivo"),
  }),
  previous_campaign_customers: string().nullable(),
  previous_campaign_to: string().when("previous_campaign_customers", {
    is: (value) => !!value,
    then: string().required("Indique si los clientes seran o no incluidos"),
    otherwise: string().transform(() => ""),
  }),
  previous_send: array()
    .of(number().typeError("Identificador envio no valido"))
    .nullable(),
  filter_comm_hierarchy: boolean().default(false),
  filter_payment_method: boolean().default(false),
  filter_condition_metric: boolean().default(false),
  filter_type_discount: boolean().default(false),
  filter_type_reward: boolean().default(false),
});

export const TextMessage = object({
  type: string(),
  text: string()
})

export const ImageMessage = object({
  type: string(),
  image : object({
    link: string()
  })
})

export const sendMethodSchema = object({
  type: string().oneOf(['email', 'sms', 'wa']).required("debe indicar un valor valido"),
  newsletter_id: number().when('type', {
    is: 'email',
    then: number().required("Se debe seleccionar una newsletter."),
    otherwise: number().nullable(),
  }),
  sender: string().when('type', {
    is: 'email',
    then: string().required("Es el nombre de origen que se vera en el correo."),
    otherwise: string().nullable(),
  }),
  sender_email: string().when('type', {
    is: 'email',
    then: string().email("El correo electronico no es valido")
      .required("Es la direccion de origen que se ve en el correo."),
    otherwise: string().nullable()
  }),
  respond_email: string().nullable(),
  subject: string().when('type', {
    is: 'email',
    then: string().required("Ingrese el asunto del correo"),
    otherwise: string().nullable()
  }),
  preheader: string().nullable(),
  sms_message: string().when('type', {
    is: 'sms',
    then: string().required("ingrese mensaje para la opción SMS"),
    otherwise: string().nullable()
  }),
  wa_phone_number:string().when('type',{
    is:'wa',
    then:string().required("seleccione un numero de origen"),
    otherwise: string().nullable()
  }),
  wa_template_id:string().when('type',{
    is:'wa',
    then: string().required("seleccione una plantilla"),
    otherwise: string().nullable()
  }),
  wa_parameters:string().nullable(),
  wa_message:string().nullable()
});


export enum Range {
  DAILY = 1,
  MONTHLY,
  NONE,
}

export enum MonthlyRepeat{
  FIRST_DAY=1,
  FIRST_BUSINESS_DAY,
  LAST_DAY,
  LAST_BUSINESS_DAY,
  SELECTED_DAY,
  DAY_OF_WEEK,
}

export enum DayWeek{
  Monday =1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum Ordinal{
  Primer = 1,
  Segundo,
  Tercer,
  Cuarto,
  Quinto,
}

export const ScheduledSchema = object({
  identifier: string().nullable(),
  name: string().required("Se requiere un nombre."),
  date_start: date().required("Indique una fecha de inicio."),
  profile: number()
    .required()
    .typeError("Se requiere seleccionar el perfile para el envio")
    .positive("perfil no valido")
    .integer("perfil no valido"),
  repeat_sending: boolean().default(false),
  sending_time: date().when("repeat_sending", {
    is: true,
    then: date().required(
      "seleccione una hora de envio."
    ),
    otherwise: date()
      .nullable()
      .transform(() => null),
  }),
  date_end: date().when("repeat_sending", {
    is: true,
    then: date().required(
      "indique una fecha para finalizar reenvios."
    ),
    otherwise: date()
      .nullable()
      .transform(() => null),
  }),
  range: number().when("repeat_sending", {
    is: true,
    then: number().required(),
    otherwise: number()
      .nullable()
      .notRequired()
      .transform(() => 0),
  }).test('is-valid-range', 'Invalid range value', function (value) {
    if (this.resolve(ref('repeat_sending'))) {
      return value === Range.DAILY || value === Range.MONTHLY;
    }
    return true;
  }),
  sending_just_x_days: array().when("range", {
    is: (value) => value === Range.DAILY,
    then: array().min(1).required("debe seleccionar los dias de la repetición"),
    otherwise: array()
      .nullable()
      .transform(() => []),
  }),
  repeat_each_month: number().when("range", {
    is: (value) => value === Range.MONTHLY,
    then: number().required("seleccione un periodo de repetición"),
    otherwise: number()
      .nullable()
      .transform(() => undefined),
  }).test('is-invalid-repeat','Invalid repeat value',function(value){
    if(this.resolve(ref('range'))===Range.MONTHLY){
      return value === MonthlyRepeat.FIRST_DAY || value === MonthlyRepeat.FIRST_BUSINESS_DAY || value === MonthlyRepeat.LAST_DAY || value===MonthlyRepeat.LAST_BUSINESS_DAY || value===MonthlyRepeat.SELECTED_DAY || value===MonthlyRepeat.DAY_OF_WEEK;
    }
    return true;
  }),
  day_the_month: number().when("repeat_each_month", {
    is: (value) => value === MonthlyRepeat.SELECTED_DAY,
    then: number().required("seleccione una fecha del mes"),
    otherwise: number()
      .nullable()
      .transform(() => null),
  }),
  ordinal: number().when("repeat_each_month", {
    is: (value) => value === MonthlyRepeat.DAY_OF_WEEK,
    then: number().required("seleccione el dia de reenvio"),
    otherwise: number()
      .nullable()
      .transform(() => undefined),
  }).test('is-invalid-repeat','Invalid repeat value',function(value){
    if(this.resolve(ref('repeat_each_month'))===MonthlyRepeat.DAY_OF_WEEK){
      return value===Ordinal.Primer || value===Ordinal.Segundo || value===Ordinal.Tercer || value===Ordinal.Cuarto || value===Ordinal.Quinto
    }
    return true;
  }),
  weekday: number().when("repeat_each_month", {
    is: (value) => value === MonthlyRepeat.DAY_OF_WEEK,
    then: number().required("seleccione el dia de reenvio"),
    otherwise: number()
      .nullable()
      .transform(() => undefined),
  }).test('is-invalid-repeat','Invalid repeat day',function(value){
    if(this.resolve(ref('weekday'))===MonthlyRepeat.DAY_OF_WEEK){
      return value===DayWeek.Monday || value===DayWeek.Tuesday || value=== DayWeek.Wednesday || value===DayWeek.Thursday || value===DayWeek.Friday || value===DayWeek.Saturday|| value===DayWeek.Sunday
    }
    return true
  }),
  active: bool().default(false),
  send_method: sendMethodSchema
});

export const UserSchema = object({
  first_name: string().required("Ingrese un nombre valido."),
  last_name: string().required("Ingrese apellidos validos."),
  email: string()
    .email("Ingrese un correo valido.")
    .required("Email requerido"),
  user_id: string(),
  active: boolean().required(),
  role: string().required(),
  address: string().required(
    "Ingrese algun tipo de direccion que identifique al usuario."
  ),
  contact_phone: string().required(
    "Ingrese un numero telefonico valido, este puede ser usado para la validación del usuario."
  ),
  password: string()
    .required("El campo contraseña es requerido")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "la contraseña debe tener 8 caracteres minimo, una mayusculas, una minusculas, un numero y un caracter especial."
    ),
  repeat_password: string()
    .required("La contraseña no coincide")
    .oneOf([ref("password")], "la contraseña no coincide"),
  confirm_password: boolean()
    .default(false)
    .when("password", {
      is: true,
      then: boolean().default(true),
    }),
  createdAt: date(),
  updatedAt: date(),
});

export const SignUpSchema = object({
  firstName: string().required("Se requiere el nombre."),
  lastName: string().required("Se requiere un apellido."),
  email: string().email("Debe ingresar una dirección de correo valida").required("se require el email"),
  address: string().nullable(),
  phone: string().nullable(),
  password: string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(/[a-z]/, "La contraseña debe tener al menos una letra minúscula")
    .matches(/[A-Z]/, "La contraseña debe tener al menos una letra mayúscula")
    .matches(/[0-9]/, "La contraseña debe tener al menos un número")
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "La contraseña debe tener al menos un caracter especial"
    )
    .required("La contraseña es obligatoria")
    .oneOf([ref("repeatPassword"), null], "Las contraseñas deben coincidir"),
  repeatPassword: string().required("Es necesario repetir la contraseña"),
  token: string().required(),
});

export const AppsRegisterClient = object({
  agree: bool().required().default(false),
  photo: string().nullable(),
  document:string().required("requerido"),
  number:string().required("requerido"),
  name:string().required("requerido"),
  lastName:string().required("requerido"),
  gender:string().nullable(),
  birthday:date().nullable(),
  coutryCode:string().required(),
  movil:string().required("requerido"),
  phoneNumber:string().nullable(),
  email:string().required("requerido"),
  maritalStatus:string().nullable(),
  address:string().nullable(),
  children:bool().nullable().default(false),
  pets:bool().nullable().default(false),
  comments:string().nullable(),
  apikey:string().required()
})

export const RegisterVisit = object({
  user : string().required("Debe ingresar un valor para la busqueda."),
  store: string().required("No hay una tienda seleccionada"),
  apikey:string().required()
})

export const SurveyInputs = object({
  ratings: array().of(number().min(1, 'Este campo es requerido').required('Este campo es requerido')),
  observations:string().nullable(),
  apikey:string().required(),
  traceid:string().required()
});

