import React from 'react';

// Enum de estados (opcional, para referencia)
export enum StateSend {
    SAVE = 'SAVE',
    SCHEDULED = 'SCHEDULED',
    SENT = 'SENT',
    ERROR = 'ERROR',
}

interface SchedulingProps {
    state: string;
}

const SchedulingIcon: React.FC<SchedulingProps>  = ({ state }) => {
    const getIcon = () => {
        switch (state) {
            case StateSend.SAVE:
                return (
                    <span className="icon has-text-info">
                        <i className="fa-solid fa-floppy-disk"/>
                    </span>
                );
            case StateSend.SCHEDULED:
                return (
                    <span className="icon has-text-success">
                        <i className="fa-regular fa-calendar-check" />
                    </span>
                );
            case StateSend.SENT:
                return (
                    <span className="icon has-text-success">
                        <i className="fa-solid fa-paper-plane" />
                    </span>
                );
            case StateSend.ERROR:
                return (
                    <span className="icon has-text-danger">
                        <i className="fa-solid fa-calendar-xmark" />
                    </span>
                );
            default:
                return null; // O algún icono por defecto si es necesario
        }
    };

    return <>{getIcon()}</>;
};

export default SchedulingIcon;